/* eslint-disable spaced-comment */
export function timeFix() {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9
    ? "早上好"
    : hour <= 11
    ? "上午好"
    : hour <= 13
    ? "中午好"
    : hour < 20
    ? "下午好"
    : "晚上好";
}

export function welcome() {
  const arr = [
    "休息一会儿吧",
    "准备吃什么呢?",
    "要不要打一把 DOTA",
    "我猜你可能累了"
  ];
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent("HTMLEvents");
  event.initEvent("resize", true, true);
  event.eventType = "message";
  window.dispatchEvent(event);
}

export function handleScrollHeader(callback) {
  let timer = 0;

  let beforeScrollTop = window.pageYOffset;
  callback = callback || function () {};
  window.addEventListener(
    "scroll",
    () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        let direction = "up";
        const afterScrollTop = window.pageYOffset;
        const delta = afterScrollTop - beforeScrollTop;
        if (delta === 0) {
          return false;
        }
        direction = delta > 0 ? "down" : "up";
        callback(direction);
        beforeScrollTop = afterScrollTop;
      }, 50);
    },
    false
  );
}

// 统一各平台接口返回的状态码的名称 retcode
export function formateInterFaceState(response) {
  //跳过为 0 的状态
  const retcode = response.data.retcode;
  const msg = response.data.msg;
  const status = response.data.status;
  const message = response.data.message;

  if (retcode === null || retcode === undefined) {
    if (status !== null && status !== undefined) {
      response.data.retcode = status;
      response.data.msg = message;
    }
  } else {
    if (status === null || status === undefined) {
      response.data.status = retcode;
      response.data.message = msg;
    }
  }

  return response;
}

export function isIE() {
  const bw = window.navigator.userAgent;
  const compare = (s) => bw.indexOf(s) >= 0;
  const ie11 = (() => "ActiveXObject" in window)();
  return compare("MSIE") || ie11;
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = "", timeout = 1500) {
  if (id === "") {
    return;
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id));
  }, timeout);
}

// 判断当前路由是否有权限
export function isPermisonEnter(path = "", pathList = [], result = []) {
  if (!path || !pathList.length) {
    result.push(false);
  } else {
    pathList.forEach((item) => {
      // 状态为显示 且路由相等
      if (item.path === path && !item.hidden) {
        result.push(true);
      } else if (item.children && !result.length) {
        isPermisonEnter(path, item.children, result);
      }
    });
  }
}

// 获取权限数据第一个导航
export function getFirstRouterPage(pathList = [], result = []) {
  if (!pathList.length) {
    result = [];
  } else {
    pathList.forEach((item) => {
      // 状态为显示 且路由存在
      if (!item.hidden && item.path && !result.length) {
        result.push(item.path);
      } else if (item.children && item.children.lenth && !result.length) {
        getFirstRouterPage(item.children, result);
      }
    });
  }
}
