import { NOT_PRODUCTION } from "./constants";
export * from "./constants";
export * from "./request";

/**
 * 日志输出
 * @param  {...any} args
 */
export function log(...args) {
  if (NOT_PRODUCTION && window.console) {
    console.log(...args);
  }
}
