<!--
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-28 15:22:44
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-04-25 16:38:56
-->
<template>
  <router-view v-if="forceReloade"></router-view>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { queryProjectList } from "@/api/smaxios";
export default {
  name: "EmptyLayout",
  data() {
    return {
      forceReloade: true
    };
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  computed: {
    ...mapGetters(["projectId", "spaceId"])
  },
  watch: {
    projectId: {
      immediate: true,
      handler() {
        this.reload && this.reload();
      }
    },
    spaceId: {
      immediate: true,
      handler(val) {
        if (val) {
          console.log(val, 999);
          this.init();
        }
      }
    }
  },
  methods: {
    ...mapActions(["setSelectedProject"]),
    reload() {
      this.forceReloade = false;
      this.$nextTick(() => {
        this.forceReloade = true;
      });
    },
    init() {
      queryProjectList({
        pageNo: 1,
        pageNum: 1,
        pageSize: 100
      }).then((res) => {
        if (res.retcode == 0 && res.data != null) {
          let list = res.data.list;
          if (!list.length) {
            this.$message.warn("当前平台没有可管理的空间!");
            return;
          }
          let arr = [];
          if (this.spaceId) {
            arr = list.filter(
              (node) => node.serverSpaceId === Number(this.spaceId)
            );
          }
          let item = arr.length ? arr[0] : list[0];
          console.log(item, 8888);
          this.setSelectedProject(item);
        }
      });
    }
  }
};
</script>
