/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-04-03 13:31:52
 * @LastEditors: gwb
 * @LastEditTime: 2023-07-24 10:01:03
 */
import { sysLog } from "@/api/logs";
import store from "@/store";
const logstore = {
  state: {},
  mutations: {},
  actions: {
    // 添加日志
    addLog({ commit }, params) {
      if (params?.operatorAction) {
        sysLog({
          ...params,
          operatorTerminalType: 0,
          projectId: store.getters.projectId,
          platfrom: process.env.VUE_APP_TERRACE
        })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
};
export default logstore;
