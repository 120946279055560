/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-04-03 13:34:53
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-04-03 14:03:04
 */
import { axios } from "@/utils/request";
import { ymConnect } from "@/utils/constants";
export function sysLog(parameter) {
  return axios({
    url: `${ymConnect}/ym-business-point-data/u/point-data/v2/sys-log/add`,
    method: "post",
    data: parameter
  });
}
