/*
 * @Author             : Felix
 * @Email              : 307253927@qq.com
 * @Date               : 2022-05-23 18:12:05
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-03-28 14:46:43
 */
// @ts-nocheck
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    qiankunState: {}
  },
  actions: {
    qiankunState(_, state) {
      console.log(state);
      // commit("qiankunState", state);
      // 通过乾坤的回调事件来触发 mutations修改
      if (Vue.setQiankunGlobalState) {
        Vue.setQiankunGlobalState(state);
      }
    }
  },
  mutations: {
    qiankunState(state, payload) {
      state.qiankunState = payload || {};
    }
  }
};
