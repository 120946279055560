/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-27 10:55:06
 * @LastEditors: gwb
 * @LastEditTime: 2023-07-24 09:24:16
 */
/**
 * @author: 	felix
 * @email: 	307253927@qq.com
 * @date: 	2021-05-19 09:03:23
 * @last Modified by:   felix
 * @last Modified time: 2021-05-20 19:31:12
 */
"use strict";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/plugins";
import "@/styles/index.less";
import "./public-path.js";
import { message } from "ant-design-vue";

message.config({
  maxCount: 1
});

Vue.config.productionTip = false;
Vue.config.errorHandler = function f(err, vm) {
  let path = "";
  if (vm && vm.$route) {
    path = vm.$route.fullPath;
  }
  console && console.error(path, err);
};

// if (process.env.VUE_APP_ENV === "development") {
//   require("@/mocks");
// }

let instance = null;
function render(container) {
  instance = new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount(container ? container.querySelector("#main-app") : "#main-app");
}
console.log(window.__POWERED_BY_QIANKUN__, "qinkuan");
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}
export async function bootstrap() {}
export async function mount(props) {
  // 父子应用通信
  props.onGlobalStateChange((state) => {
    console.log(state, "父子应用通信");
    store.commit("qiankun/qiankunState", state);
  }, true);
  window.setQiankunState = props.setGlobalState.bind(props);
  // router.replace();
  render(props.container);
}
export async function update(props) {
  console.log(props);
}
export async function unmount() {
  instance.$destroy();
  instance = null;
}
