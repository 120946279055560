/*
 * @Author: gwb
 * @Date: 2023-07-17 10:49:24
 * @LastEditors: gwb
 * @LastEditTime: 2023-07-25 14:22:33
 * @Description:
 */
export const RE_EMAIL = /^[_a-z0-9-.]+@([-a-z0-9]+\.)+[a-z]{2,}$/i;
export const RE_PHONE = /^1[0-9]\d{9}$/;
export const RE_PASSWORD = /^[0-9A-Za-z]{6,16}$/;

export const TERRACE = "CONNECT"; //平台标识
export const TOKEN_KEY = "token";
export const TOKEN_EXPIRE = "token_expire";
export const ymConnect = "";

export const NOT_PRODUCTION = process.env.NODE_ENV !== "production";
