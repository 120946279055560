const project = {
  state: {
    // 下拉选中的项目名
    selectedProject: null,
    /// 修改的项目
    modifyProject: null,
    /// 当前执行的步聚
    currentStep: 0,
    /// 当前修改的梯控版
    paneldevice: null
  },
  mutations: {
    SETSELECT_PROJECT: (state, item) => {
      state.selectedProject = item;
    },
    SETMODIFY_PROJECT: (state, item) => {
      state.modifyProject = item;
    },
    SETSTEP_INDEX: (state, step) => {
      state.currentStep = step;
    },
    SET_PANEL_DCODE: (state, item) => {
      state.paneldevice = item;
    }
  },
  actions: {
    setSelectedProject({ commit }, pitem) {
      commit("SETSELECT_PROJECT", pitem);
    },
    setModifyProject({ commit }, pitem) {
      commit("SETMODIFY_PROJECT", pitem);
    },
    setStep({ commit }, step) {
      commit("SETSTEP_INDEX", step);
    },
    setPanelDevice({ commit }, item) {
      commit("SET_PANEL_DCODE", item);
    }
  }
};

export default project;
