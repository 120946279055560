/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-27 10:55:06
 * @LastEditors: gwb
 * @LastEditTime: 2023-07-28 10:01:24
 */
"use strict";
import axios from "axios";
import { log } from "@/utils";
import store from "@/store";
import router from "@/router";
import { loginCacheClear } from "@/utils/auth";
// import addLogs from "@/store/modules/addLogs";
import { BurialPointFun } from "@/utils/burialPointUrl";
import { message } from "ant-design-vue";

// 创建axios实例
export function createAxios(opt = {}) {
  const service = axios.create({
    baseURL: process.env.VUE_APP_SERVER,
    timeout: 30000
  });

  // TODO 拦截器需要根据项目改造
  const ignoreTokenUrls = ["/api/v1/auth/login"];
  service.interceptors.request.use(
    (config) => {
      config.headers["terrace"] = process.env.VUE_APP_TERRACE;
      let token = store.getters.token;
      if (token) {
        config.headers[process.env.VUE_APP_TOKEN] = token;
        config.headers["Access-Token"] = token;
      }

      if (store.getters.projectId) {
        config.headers.projectId = store.getters.projectId;
      }

      if (
        !token &&
        ignoreTokenUrls.indexOf(config.url) === -1 &&
        router.currentRoute.path.indexOf("/login") === -1
      ) {
        if (window.__POWERED_BY_QIANKUN__) {
          window.location.href = "/login";
        } else {
          router.replace("/login");
          store.dispatch("Logout");
        }
      }
      return config;
    },
    (error) => {
      log(error);
      return Promise.reject(error);
    }
  );

  const ErrorCodes = [1001, 1012];
  service.interceptors.response.use(
    (response) => {
      if (response.config.responseType === "blob") {
        let name = response.headers["content-disposition"] || "";
        let ret = name.match(/filename=([^;]*)/);
        response.filename = decodeURIComponent((ret && ret[1]) || "blob");
        return response;
      }
      const res = response.data;
      if (res.status === undefined) {
        res.status = res.retcode;
      } else if (res.retcode === undefined) {
        res.retcode = res.status;
      }
      if (response.status === 403 || ErrorCodes.indexOf(res.retcode) > -1) {
        loginCacheClear();
        store.dispatch("Logout");
        if (window.__POWERED_BY_QIANKUN__) {
          window.location.href = "/login";
        } else {
          router.replace("/login");
        }
        return Promise.reject({ msg: res.msg || "登录信息失效" });
      } else if (res.retcode && res.retcode !== 0) {
        if (res.retcode === 403) {
          //无权限
          if (window.__POWERED_BY_QIANKUN__) {
            window.location.href = "/404";
          }
        }
        message.error(res.msg);
        addLog(response, "失败");
        return Promise.reject({
          msg: res.msg || "调用服务失败",
          message: res.msg || "调用服务失败"
        });
      } else {
        // 埋点
        addLog(response, "成功");
        return res;
      }
    },
    (error) => {
      log("err" + error); // for debug
      // 埋点
      addLog(error, "失败");
      return Promise.reject(error);
    }
  );

  return service;
}

//埋点
function addLog(response, resText) {
  const BurialPointObj = BurialPointFun();
  for (let key in BurialPointObj) {
    if (key === response.config.url) {
      const pramas = {
        ...BurialPointObj[key],
        operatorResult: resText
      };
      // if (key === "/api/v1/auth/login") {
      //   const name =
      //     response.data?.data?.member?.relaname ||
      //     response.data?.data?.member?.mobile;
      //   //单独处理登录
      //   pramas.content = (name ? `【${name}】` : "") + pramas.content;
      //   //延时调用接口，储存token
      //   setTimeout(() => {
      //     store.dispatch("addLog", pramas);
      //   }, 500);
      // } else {
      //   store.dispatch("addLog", pramas);
      // }
      store.dispatch("addLog", pramas);
    }
  }
}

const request = createAxios({
  baseURL: ""
});

export default request;
export { request as axios };
