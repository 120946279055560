/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-27 10:55:06
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-03-30 17:24:40
 */
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import getters from "./getters";

Vue.use(Vuex);
if (window.__POWERED_BY_QIANKUN__) {
  const Storage = require("./storage").default;
  Storage.install();
  console.info("子应用 localstorage、sessionstorage 已隔离");
}

export default new Vuex.Store({
  state: {
    collapsed: false,
    menuTree: []
  },
  actions: {
    setCollapse({ commit }, value) {
      commit("collapsed", value);
    },
    setMenuTree({ commit }, list) {
      commit("menuTree", list);
    }
  },
  mutations: {
    collapsed(state, value) {
      state.collapsed = !!value;
    },
    menuTree(state, list) {
      state.menuTree = filterMenu(list || [], 0);
    }
  },
  modules: {
    ...loadModules()
  },
  getters: {
    ...getters
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
});

/**
 * 动态加载 modules 中的vuex模块
 * @returns
 */
function loadModules() {
  const modulesFiles = require.context("./modules", true, /\.(j|t)s$/i);
  const regx = /^\.\/(.*)\.\w+$/;
  const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(regx, "$1");
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
  }, {});
  return modules || {};
}

function filterMenu(list, level) {
  if (list) {
    list = list.filter((item) => {
      if (item.status === 1) {
        if (level) {
          item.icon = null;
        }
        if (item.childList) {
          item.childList = filterMenu(item.childList, 1);
        }
        return true;
      }
    });
  }
  return list;
}
