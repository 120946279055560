import { axios } from "@/utils/request";
import { ymConnect } from "@/utils/constants";
import { loginCacheClear } from "@/utils/auth";
import router from "@/router";

/// 导入main.js找到this进行处理路由
// eslint-disable-next-line camelcase
function smPost_request({ url, data }) {
  return new Promise((resolve, reject) => {
    axios({ url, method: "post", data })
      .then((response) => {
        const retcode = response.retcode;
        /// 退出到登录
        if (retcode === 1001 || retcode === 1012) {
          loginCacheClear();
          router.push({ path: "/user/login" });
          // alert(msg)
          return;
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/// 登录信息
export function smLogin(parameter) {
  return axios({
    url: ymConnect + "/api/v1/auth/login",
    method: "post",
    data: parameter
  });
}

/// 获取项目列表
export function queryProjectList(parameter) {
  parameter.pageSize = 100;
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/project",
    data: parameter
  });
}
/// 通过项目id查询项目服务配置
export function fetchThirdPartyInfoByProjectId(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v1/project/server/setting/querySettingsForMap",
    data: parameter
  });
}
/// 更新项目服务的值 (projectServerSettingId,value)
export function updateThirdServicePropertyValue(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v1/project/server/setting/updValueBatch",
    data: parameter
  });
}

/// 获取项目对应的梯控板列表,入参为spaceId
export function queryProjectLiftPanelList(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v1/liftControl/management/device/liftControlPanel",
    data: parameter
  });
}

/// 获取梯控板详情数据 入参为deviceCode
export function queryLiftPanelDetail(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/device",
    data: parameter
  });
}

/// 获取电梯列表,入参deviceCode
export function queryLiftList(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v1/liftControl/management/device/subset",
    data: parameter
  });
}

/// 修改设备属性
export function editDeviceAttributes(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/device/editAttribute",
    data: parameter
  });
}
/// 下发电梯/梯控版
export function sendcommandbyCode(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v1/liftControl/management/device/issue",
    data: parameter
  });
}
/// 下发人脸
export function sendfaceCmdbyCode(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/device/face/pub",
    data: parameter
  });
}

/// 查询搜索接口
export function searchDevicebyCode(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v1/liftControl/management/device/page",
    data: parameter
  });
}
/// 搜索绑定接口
export function searchCanBindDevice(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v1/liftControl/management/device/page/binding",
    data: parameter
  });
}

export function deleteBindByCode(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v1/liftControl/management/device/subset/remove",
    data: parameter
  });
}

export function todoBindDeviceBycode(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v1/liftControl/management/device/subset/binding",
    data: parameter
  });
}

/// 获取菜单
export function toloadSildMenuRouters(projectId) {
  return smPost_request({
    url: ymConnect + "/ym-auth-server/u/auth-server/v2/access/loadMenu",
    data: { projectId }
  });
}

/// 检测权限
export function todoCheckPromission(path) {
  return smPost_request({
    url: ymConnect + "/ym-auth-server/u/auth-server/v2/access/checkPermission",
    data: { requestPath: path }
  });
}

// 重置节点
export function resetNode(parameter) {
  return axios({
    url:
      ymConnect +
      "/api/v2/liftControl/management/project/spaceExtension/delete",
    method: "get",
    params: parameter
  });
}

// 楼宇模型链路置灰判断
export function traceGray(parameter) {
  return axios({
    url: ymConnect + "/api/v2/liftControl/management/space/floorMap/traceGray",
    method: "get",
    params: parameter
  });
}

// 类型字典：
export function getElevatorSpaceType(parameter) {
  return axios({
    url: ymConnect + "/api/v2/liftControl/management/dict/elevatorSpaceType",
    method: "get",
    params: parameter
  });
}

// 群控器设置的数据
export function queryNearbyIpcBySpaceId(parameter) {
  return axios({
    url:
      ymConnect +
      "/api/v2/liftControl/management/project/space/queryNearbyIpcBySpaceId",
    method: "get",
    params: parameter
  });
}

// 新增|修改|保存
export function addOrUpdate(parameter) {
  return smPost_request({
    url:
      ymConnect +
      "/api/v2/liftControl/management/project/spaceExtension/addOrUpdate",
    data: parameter
  });
}

// 显示楼层的数据：
export function getFloorMap(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/project/space/floorMap",
    data: parameter
  });
}

// 群控器ID的数据
export function queryNearbyClusterControllerIdsBySpaceId(parameter) {
  return axios({
    url:
      ymConnect +
      "/api/v2/liftControl/management/project/space/queryNearbyClusterControllerIdsBySpaceId",
    method: "get",
    params: parameter
  });
}

//* *********V2*************/
// 查询项目下的空间构架
export function fetchSpaceTrees(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/project/spaceFramework",
    data: parameter
  });
}

// 搜索项目下的空间结构类型
// export function fetchSpaceMap(parameter) {
//     return smPost_request({ url: ymConnect + '/api/v2/liftControl/management/project/spaceFramework/typeForMap', data: parameter })
// }

/// 新增扩展属性
export function addNewAttrs(parameter) {
  return smPost_request({
    url:
      ymConnect +
      "/api/v2/liftControl/management/project/space/addSpaceExtension",
    data: parameter
  });
}

/// 修改属性
export function updateAttrs(parameter) {
  return smPost_request({
    url:
      ymConnect +
      "/api/v2/liftControl/management/project/space/editSpaceExtension",
    data: parameter
  });
}

/// 查询工控机列表
export function queryAllGongKongJiList(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/device/ipc",
    data: parameter
  });
}

/// 查询已绑定的人脸设备
export function queryHaveBindFaceList(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/device/binding",
    data: parameter
  });
}

/// 查询未绑定的人脸设备
export function queryUnBindFaceList(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/device/notBinding",
    data: parameter
  });
}

/// 查询人脸门禁设备
export function queryFaceListByIpc(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/device/subset",
    data: parameter
  });
}

/// 解除人脸门禁设备
export function unBindFaceDevice(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/device/subset/remove",
    data: parameter
  });
}

/// 获取楼层模型
export function fetchFloorMap(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/project/space/floorMap",
    data: parameter
  });
}

/// 批量绑定设备
export function batchBindSubDevices(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/management/device/subset/binding",
    data: parameter
  });
}

/// 下发梯控数据
export function sendGongKong(parameter) {
  return smPost_request({
    url: ymConnect + "/api/v2/liftControl/device/ipc/pub",
    data: parameter
  });
}

export function ObjectList(parameter) {
  parameter.pageSize = 100;
  return axios({
    url: ymConnect + "/api/v1/liftControl/management/project",
    method: "post",
    data: parameter
  });
}

// 获取梯控板列表
export function ControlList(parameter) {
  return axios({
    url: ymConnect + "/api/v1/liftControl/management/device/liftControlPanel",
    method: "post",
    data: parameter
  });
}

// 查询modal详情页面
export function getDetail(parameter) {
  return axios({
    url: ymConnect + "/api/v1/liftControl/management/device",
    method: "post",
    data: parameter
  });
}

// 查询第三方梯控id
export function getThirdPartyProjectId(parameter) {
  return axios({
    url: ymConnect + "/api/v1/project/server/setting/querySettingsForMap",
    method: "post",
    data: parameter
  });
}

// 获取电梯列表
export function ElevatorList(parameter) {
  return axios({
    url: ymConnect + "/api/v1/liftControl/management/device/subset",
    method: "post",
    data: parameter
  });
}

// 编辑设备信息接口
export function Compile(parameter) {
  return axios({
    url: ymConnect + "/api/v1/liftControl/management/device/editAttribute",
    method: "post",
    data: parameter
  });
}

// 人脸下发
export function FaceIssue(parameter) {
  return axios({
    url: ymConnect + "/api/v1/liftControl/management/device/face/issue",
    method: "post",
    data: parameter
  });
}

// 修改项目ID
export function UpdValueBatch(parameter) {
  return axios({
    url: ymConnect + "/api/v1/project/server/setting/updValueBatch",
    method: "post",
    data: parameter
  });
}

// 初始化
export function init(parameter) {
  return axios({
    url: ymConnect + "/api/v1/liftControl/management/user/init",
    method: "post",
    data: parameter
  });
}

// 获取人脸门禁安装区域：
export function getDictItemByCode(params) {
  return axios({
    url:
      ymConnect +
      `/api/v2/liftControl/management/dict/item?dictCode=${params.dictCode}`,
    method: "get"
  });
}
