/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-27 10:55:06
 * @LastEditors: gwb
 * @LastEditTime: 2023-07-24 11:30:26
 */
const getters = {
  collapsed: (state) => state.collapsed,
  menuTree: (state) => state.menuTree,
  project: (state) => state.project,
  projectId: (state) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      return state.app.projectId;
    }
    return state.qiankun.qiankunState.projectId;
  },
  spaceId: (state) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      return state.project?.selectedProject?.serverSpaceId;
    }
    return state.qiankun.qiankunState.spaceId;
  },
  device: (state) => state.app.device,
  theme: (state) => state.app.theme,
  color: (state) => state.app.color,
  appLoading: (state) => state.user.appLoading,
  token: (state) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      return state.user.token;
    }
    return state.qiankun.qiankunState.token;
  },
  avatar: (state) => state.user.avatar,
  nickname: (state) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      return state.user.name;
    }
    return state.qiankun.qiankunState.user.relaname;
  },
  welcome: (state) => state.user.welcome,
  roles: (state) => state.user.roles,
  userInfo: (state) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      return state.user.info;
    }
    return state.qiankun.qiankunState.user;
  },
  multiTab: (state) => state.app.multiTab,
  getNameOrPhone: (state) => {
    let info = {};
    if (!window.__POWERED_BY_QIANKUN__) {
      info = state.user.info;
    } else {
      info = state.qiankun.qiankunState.user;
    }
    let name = info.relaname || info.mobile || "";
    return name ? "【" + name + "】" : "";
    // const name = `【${
    //   state.user?.info?.relaname || state.user?.info?.mobile || ""
    // }】`;
    // return name === "【】" ? "" : name;
  }
};

export default getters;
