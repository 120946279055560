<template>
  <a-config-provider :locale="locale" :getPopupContainer="getPopupContainer">
    <router-view />
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  data() {
    return {
      locale: zhCN
    };
  },
  methods: {
    getPopupContainer(el, dialogContext) {
      if (dialogContext) {
        return dialogContext.getDialogWrap() || document.body;
      } else if (el) {
        return el.parentNode || document.body;
      }
      return document.body;
    }
  }
};
</script>
