/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-03-27 10:55:06
 * @LastEditors: renxiaodi@yunmaohulian.com
 * @LastEditTime: 2023-04-25 13:40:42
 */
/**
 * @author:	felix
 * @email:	307253927@qq.com
 * @date:	2020-07-03 09:03:23
 * @last Modified by: felix
 * @last Modified time: 2020-07-03 09:03:23
 */
"use strict";
import Vue from "vue";
import VueRouter from "vue-router";
// import eventTracking from "@/eventTracking";
import { routes, asyncRoutes } from "./routes";
// import { ROLE } from "@/utils";
import store from "../store";

import { getToken, getUser } from "@/utils/auth";

Vue.use(VueRouter);

// 修复 Error: Avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch((err) => err);
};

/**
 * 根据用户角色动态生成路由
 * @param {*} role 用户角色
 * @returns
 */
function generateAsyncRoutes(/* role */) {
  return routes.concat(
    asyncRoutes
    // role === ROLE.super
    //   ? asyncRoutes
    //   : asyncRoutes.filter(r => {
    //       if (!r.meta || r.meta.role !== ROLE.super) {
    //         return true;
    //       }
    //     })
  );
}

const router = new VueRouter({
  base: window.__POWERED_BY_QIANKUN__
    ? "/" + process.env.VUE_APP_SUBAPPNAME
    : process.env.BASE_URL,
  routes: generateAsyncRoutes()
  // routes: store.getters.isExpire ? routes : generateAsyncRoutes(),
  // : generateAsyncRoutes(store.getters.isSuperAdmin ? ROLE.super : undefined)
});

// eventTracking.broswerInfo();
// router.afterEach((to, from) => {
//   eventTracking.switchPage(to, from);
// });

/**
 * 根据角色信息初始化路由
 * 登录后调用
 * @param {*} role
 */
export function initialRouter(role) {
  router.matcher = new VueRouter({
    routes: generateAsyncRoutes(role)
  }).matcher;
}

router.beforeEach((to, from, next) => {
  if (!store.getters.token && to.query.from === "iot-platform") {
    store.dispatch("setLoad", true);
    // 单点登录
    if (getToken()) {
      store
        .dispatch("saveUserInfo", {
          token: getToken(),
          member: getUser()
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  } else {
    store.dispatch("setLoad", false);
  }
  if (!to.query.from && !store.getters.token && to.path !== "/user/login") {
    router.replace("/user/login");
  } else {
    next();
  }
});

export default router;
