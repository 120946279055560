import Vue from "vue";
import { login, getInfo } from "@/api/login";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { welcome } from "@/utils/util";
import { loginCacheClear } from "@/utils/auth";

const user = {
  state: {
    token: "",
    name: "",
    welcome: "",
    avatar: "",
    roles: [],
    info: {},
    appLoading: false
  },

  mutations: {
    LOADING: (state, loading) => {
      state.appLoading = loading;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name || "";
      state.welcome = welcome;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar || "";
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_INFO: (state, info) => {
      if (info) {
        state.name = info.relaname || "";
        state.avatar = info.avatar || "";
      }
      state.info = info;
    }
  },

  actions: {
    setLoad({ commit }, load) {
      commit("LOADING", load || false);
    },
    setUserInfo({ commit }, userInfo) {
      commit("SET_INFO", userInfo || {});
    },
    async saveUserInfo({ commit }, value) {
      return new Promise((resolve, reject) => {
        if (value.member && value.token) {
          commit("SET_INFO", value.member);
          commit("SET_TOKEN", value.token);
          // 获取到token之后调用init接口
          resolve();
        } else {
          reject();
        }
      });
    },
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            const result = response.result;
            Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000);
            commit("SET_TOKEN", result.token);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const result = response.result;

            if (result.role && result.role.permissions.length > 0) {
              const role = result.role;
              role.permissions = result.role.permissions;
              role.permissions.map((per) => {
                if (
                  per.actionEntitySet != null &&
                  per.actionEntitySet.length > 0
                ) {
                  const action = per.actionEntitySet.map((action) => {
                    return action.action;
                  });
                  per.actionList = action;
                }
              });
              role.permissionList = role.permissions.map((permission) => {
                return permission.permissionId;
              });
              commit("SET_ROLES", result.role);
              commit("SET_INFO", result);
            } else {
              reject(new Error("getInfo: roles must be a non-null array !"));
            }

            commit("SET_NAME", { name: result.name, welcome: welcome() });
            commit("SET_AVATAR", result.avatar);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 登出
    Logout({ commit }) {
      commit("SET_TOKEN", "");
      commit("SET_ROLES", []);
      commit("SET_INFO", {});
      loginCacheClear();
      // return new Promise((resolve) => {
      //   logout(getToken())
      //     .then(() => {
      //       resolve();
      //     })
      //     .catch(() => {
      //       resolve();
      //     })
      //     .finally(() => {
      //       commit("SET_TOKEN", "");
      //       commit("SET_ROLES", []);
      //       loginCacheClear();
      //     });
      // });
    }
  }
};

export default user;
