<template>
  <a-layout class="layout">
    <!-- <Menu /> -->
    <a-layout>
      <Nav />
      <!-- <Breadcrumb /> -->
      <a-layout-content ref="content" class="container">
        <keep-alive v-if="keepAlive">
          <router-view></router-view>
        </keep-alive>
        <router-view v-else></router-view>
      </a-layout-content>
    </a-layout>
    <!-- <a-back-top :visibilityHeight="200" :target="target" /> -->
  </a-layout>
</template>
<script>
// import Menu from "./Menu.vue";
import Nav from "./Nav.vue";
// import Breadcrumb from "./Breadcrumb.vue";
export default {
  name: "MainLayout",
  components: {
    // Menu,
    Nav
    // Breadcrumb
  },
  computed: {
    keepAlive() {
      return this.$route.meta?.keepAlive;
    }
  },
  methods: {
    target() {
      return this.$refs.content.$el;
    }
  }
};
</script>
<style lang="less" scoped>
.layout {
  height: 100%;
  .ant-layout > .container {
    position: relative;
    padding: 20px;
    overflow: auto;
  }
  .container.panel {
    padding: 30px;
  }
  .ant-layout-footer {
    font-size: 12px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
