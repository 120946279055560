import api from "./index";
import { axios } from "@/utils/request";
import { ymConnect } from "@/utils/constants";

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return axios({
    url: ymConnect + "/auth/login",
    method: "post",
    data: parameter
  });
}

export function getSmsCaptcha(parameter) {
  return axios({
    url: api.SendSms,
    method: "post",
    data: parameter
  });
}

export function getInfo() {
  return axios({
    url: ymConnect + "/user/info",
    method: "get"
  });
}

export function getCurrentUserNav() {
  return axios({
    url: ymConnect + "/user/nav",
    method: "get"
  });
}

export function logout() {
  return axios({
    url: ymConnect + "/api/v1/auth/logout",
    method: "post"
  });
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step(parameter) {
  return axios({
    url: api.twoStepCode,
    method: "post",
    data: parameter
  });
}
