<template>
  <div>
    <a-layout-header class="layout-head">
      <p class="til">梯控管理平台</p>
      <div class="right-container">
        <a-dropdown v-if="currentItem">
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item v-for="(prjitem, idx) in projectList" :key="idx">{{
              prjitem.projectName
            }}</a-menu-item>
          </a-menu>
          <a-button>
            {{ currentItem.projectName }}
            <a-icon type="down" />
          </a-button>
        </a-dropdown>
        <a-popover overlayClassName="sys-pop" placement="bottomRight">
          <template slot="content">
            <div>
              <a-button type="link" class="item" @click="go2logout">
                <a-icon type="logout" />
                退出系统
              </a-button>
            </div>
          </template>
          <div class="user-info">
            <a-avatar :size="32" icon="user" :src="avatar" />
            <span>{{ nickname }}</span>
          </div>
        </a-popover>
      </div>
    </a-layout-header>
    <a-modal
      title="空间选择"
      class="masking"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :mask="false"
      :centered="true"
      :closable="false"
      @ok="handleOk"
    >
      <div class="selCoat" v-if="projectName">
        <a-select class="selItem" v-model="projectName" @change="handleSel">
          <a-select-option
            v-for="(item, index) in projectList"
            :value="item.projectId"
            :key="index"
          >
            {{ item.projectName }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { Modal } from "ant-design-vue";
import { mapGetters, mapActions } from "vuex";
import { logout } from "@/api/login";
import { getSpaceId } from "@/utils/auth";
import { toloadSildMenuRouters, queryProjectList } from "@/api/smaxios";

export default {
  name: "Nav",
  data() {
    return {
      currentItem: null,
      projectList: [],
      visible: false,
      confirmLoading: false,
      projectName: "",
      selItem: ""
    };
  },
  computed: {
    ...mapGetters([
      "collapsed",
      "nickname",
      "avatar",
      "project",
      "projectId",
      "spaceId"
    ])
  },
  methods: {
    ...mapActions([
      "setCollapse",
      "setMenuTree",
      "setSelectedProject",
      "changeProject",
      "Logout"
    ]),
    go2info() {
      this.selectedKeys = [];
      // this.$router.push("/setting");
    },
    handleSel(e) {
      let selected = {};
      this.projectList.forEach((item) => {
        if (e === item.projectId) {
          selected = item;
        }
      });
      if (selected.projectId != this.currentItem?.projectId) {
        // 动态读取现在的菜单刷新菜单列表
        this.selItem = selected;
      }
    },
    handleOk() {
      this.visible = false;
      if (this.selItem) {
        this.currentItem = this.selItem;
      }
      this.getMenuList(this.currentItem.projectId, this.currentItem);
    },
    go2logout() {
      let thif = this;
      this.$confirm({
        title: "提示",
        content: "真的要注销登录吗 ?",
        onOk: () => {
          logout()
            .then(() => {
              thif.Logout();
              thif.$router.push("/user/login");
            })
            .catch((error) => {
              console.log(`退出异常： ${error}`);
              thif.Logout();
              thif.$router.push("/user/login");
            });
        },
        onCancel() {}
      });
    },
    handleMenuClick(e) {
      const selected = this.projectList[e.key];
      if (selected.projectId != this.currentItem?.projectId) {
        // 动态读取现在的菜单刷新菜单列表
        const prjid = selected.projectId;
        this.getMenuList(prjid, selected);
      } else {
        console.log("不需要改变");
      }
    },
    getMenuList(prjid, selected) {
      this.changeProject(prjid);
      toloadSildMenuRouters(prjid)
        .then((res) => {
          if (res.status === 0) {
            // 切换选择
            if (selected) {
              this.currentItem = selected;
              this.projectName = this.currentItem.projectName;
              this.setSelectedProject(selected);
            }
            // console.log("setMenuTree", res.data);
            // 构建路由菜单
            this.setMenuTree(res.data?.childList || []);
          } else if (res.status === 2082000) {
            // 无权限
            Modal.warning({
              title: "提示：",
              okText: "确定",
              content: "您没有访问该系统的权限!"
            });
          } else {
            // 无权限不让切换
            this.$message.info(res.message);
          }
        })
        .catch(() => {
          this.$message.error("加载菜单列表失败");
        });
    },
    init() {
      queryProjectList({
        pageNo: 1,
        pageNum: 1,
        pageSize: 100
      }).then((res) => {
        if (res.retcode == 0 && res.data != null) {
          this.projectList = res.data.list;
          if (!this.projectList.length) {
            this.$message.warn("当前平台没有可管理的空间!");
            this.Logout();
            this.$router.push("/user/login");
            return;
          }
          let arr = [];
          let id =
            this.spaceId ||
            getSpaceId() ||
            this.project?.selectedProject?.serverSpaceId;
          if (id) {
            arr = this.projectList.filter(
              (node) => node.serverSpaceId === Number(id)
            );
          }
          this.currentItem = arr.length ? arr[0] : this.projectList[0];
          this.projectName = this.currentItem.projectName;
          this.getMenuList(this.currentItem?.projectId, this.currentItem);
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>
<style lang="less" scoped>
.layout-head {
  position: relative;
  background-color: white;
  width: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  z-index: 1;
  .project {
    margin: 0 10px 0 40px;
  }
  .ant-cascader-picker {
    margin-left: 2rem;
    min-width: 450px;
  }
  .user-info {
    float: right;
    padding-left: 24px;
    .ant-avatar {
      margin-right: 8px;
    }
    span {
      vertical-align: middle;
    }
  }
  &::after {
    content: "";
    clear: both;
  }
}
.til {
  font-size: 16px;
  font-weight: 550;
  letter-spacing: 1px;
}
.right-container {
  position: absolute;
  right: 20px;
  top: 0;
}
.selCoat {
  display: flex;
  justify-content: center;
  align-items: center;
  .selItem {
    width: 200px;
  }
}
.masking {
  /deep/ .ant-btn {
    display: none;
  }
  /deep/ .ant-btn-primary {
    display: inline-block;
  }
}
</style>
