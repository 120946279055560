import Cookies from "js-cookie";

// cookie保存的天数
const cookieExpires = 1;

export const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY;
export const USER_KEY = process.env.VUE_APP_USERINFO;
export const SPACEID = process.env.VUE_APP_CUR_SPACEID;
export const DOMAIN = process.env.VUE_APP_DOMAIN;

export const setToken = (token) => {
  Cookies.set(TOKEN_KEY, token, { expires: cookieExpires, domain: DOMAIN });
};

export const setUserInfo = (userinfo) => {
  Cookies.remove(USER_KEY, { domain: DOMAIN });
  Cookies.set(USER_KEY, userinfo, { expires: cookieExpires, domain: DOMAIN });
};

export const setSpaceId = (token) => {
  Cookies.set(SPACEID, token, { expires: cookieExpires, domain: DOMAIN });
};

export const getSpaceId = () => {
  const spaceId = Cookies.get(SPACEID, { domain: DOMAIN });
  if (spaceId) return spaceId;
  else return false;
};

export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY, { domain: DOMAIN });
  console.log("token00000000", token);
  if (token) return token;
  else return false;
};

export const getUser = () => {
  const user = Cookies.get(USER_KEY, { domain: DOMAIN });
  if (user) {
    try {
      return JSON.parse(user);
    } catch (err) {
      console.log(err);
    }
  } else return false;
};

export function loginCacheClear() {
  Cookies.remove(TOKEN_KEY, { domain: DOMAIN });
  Cookies.remove(SPACEID, { domain: DOMAIN });
  Cookies.remove(USER_KEY, { domain: DOMAIN });
  localStorage.clear();
  sessionStorage.clear();
}
