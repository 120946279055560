/*
 * @Author: renxiaodi@yunmaohulian.com
 * @Date: 2023-04-03 15:28:15
 * @LastEditors: gwb
 * @LastEditTime: 2023-07-24 15:56:31
 */
import store from "../store";
// operatorAction  0新增，1查询，2修改，3删除，4登录. 5导入. 6导出. 7下发. 8解绑
// 埋点接口
export const BurialPointFun = () => {
  const name = store.getters.getNameOrPhone;
  return {
    // "/api/v1/auth/login": {
    //   content: "【登录】了【梯控管理平台】",
    //   operatorAction: 4
    // },
    "/api/v2/liftControl/management/project/spaceFramework": {
      content: `${name}【查看】了【梯控管理-空间架构-列表】`,
      operatorAction: 1
    },
    "/api/v2/liftControl/device/query-elevator": {
      content: `${name}【查看】了【梯控管理-电梯管理-列表】`,
      operatorAction: 1
    },
    "/api/v1/liftControl/management/device/page": {
      content: `${name}【查看】了【梯控管理-人脸门禁-列表】`,
      operatorAction: 1
    },
    "/api/v2/liftControl/device/query-cluster-controller": {
      content: `${name}【查看】了【梯控管理-群控器-列表】`,
      operatorAction: 1
    },
    "/api/v2/liftControl/management/device/ipc": {
      content: `${name}【查看】了【梯控管理-工控机-列表】`,
      operatorAction: 1
    },
    "/api/v2/liftControl/management/project/space/floorMap": {
      content: `${name}【查看】了【梯控管理-电梯管理-详情】`,
      operatorAction: 1
    },
    "/api/v2/liftControl/management/project/spaceExtension/addOrUpdate": {
      content: `${name}【编辑】了【梯控管理-空间架构】`,
      operatorAction: 2
    },
    "/api/v2/liftControl/management/project/spaceExtension/delete": {
      content: `${name}【编辑】了【梯控管理-空间架构-重置】`,
      operatorAction: 2
    },
    "/api/v2/liftControl/management/device/editAttribute": {
      content: `${name}【编辑】了【梯控管理-电梯管理】`,
      operatorAction: 2
    },
    "/api/v2/liftControl/device/face/pub": {
      content: `${name}【下发】了【梯控管理-人脸门禁-下发数据按钮】`,
      operatorAction: 7
    },
    "/api/v2/liftControl/management/device/subset/remove": {
      content: `${name}【编辑】了【梯控管理-工控机-解绑】`,
      operatorAction: 2
    },
    "/api/v2/liftControl/management/device/subset/binding": {
      content: `${name}【编辑】了【梯控管理-工控机-绑定人脸门禁】`,
      operatorAction: 2
    },
    "/api/v2/liftControl/device/edit-cluster-controller": {
      content: `${name}【编辑】了【梯控管理-群控器】`,
      operatorAction: 2
    }
  };
};
