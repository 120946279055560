import MainLayout from "@/components/layout/Layout.vue";
import EmptyLayout from "@/components/layout/EmptyLayout.vue";
import EmptyLayoutQiankun from "@/components/layout/EmptyLayoutQiankun.vue";
let Layout = MainLayout;
if (window.__POWERED_BY_QIANKUN__) {
  Layout = EmptyLayoutQiankun;
}

/**
 * 路由 meta 信息说明
 * meta: { name: "项目管理", index: "list", hideName: false, hideBreadcrumb: false, keepAlive }
 *    name: 路由名称， index: 有子路由时指定默认页， hideName: 隐藏页面标题， hideBreadcrumb: 隐藏面包屑
 */
export const routes = [
  {
    path: "/login",
    redirect: "/user/login"
  },
  {
    path: "/user",
    redirect: "/user/login",
    component: EmptyLayout,
    children: [
      {
        path: "login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/Login")
      },
      {
        path: "register",
        name: "register",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/Register")
      },
      {
        path: "register-result",
        name: "registerResult",
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/RegisterResult")
      }
    ]
  },
  {
    path: "/404",
    component: () => import("@/views/errorPage/404")
  },
  {
    path: "*",
    redirect: "/404"
  }
];

export const asyncRoutes = [
  {
    path: "/",
    redirect: "/projects/projects"
  },
  {
    path: "/projects",
    component: Layout,
    meta: { name: "工作台", index: "projects" },
    redirect: "/projects/projects",
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/index/Index"),
        meta: { name: "跳转页", keepAlive: false }
      },
      {
        path: "projects",
        name: "projects",
        component: () =>
          import(/* webpackChunkName: "projects" */ "@/views/step/index"),
        meta: { name: "首页", keepAlive: false }
      },
      {
        path: "projects1", // 测试路由 上线删除
        name: "projects1",
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "@/views/list/projects/ProjectsList"
          ),
        meta: { name: "首页", keepAlive: false }
      },
      {
        path: "prj_detail",
        name: "prj_detail",
        component: () =>
          import(
            /* webpackChunkName: "projects" */ "@/views/list/projects/ProjectsDetail"
          ),
        meta: {
          name: "项目详情",
          keepAlive: false,
          hidden: true
        }
      }
    ]
  },
  {
    path: "/list",
    component: Layout,
    meta: { name: "工作台", index: "panels" },
    children: [
      {
        path: "panels",
        name: "LiftPanelList",
        component: () =>
          import(
            /* webpackChunkName: "panels" */ "@/views/list/panels/LiftPanelPage"
          ),
        meta: {
          name: "梯控板信息",
          keepAlive: false
        }
      },
      {
        path: "lifts",
        name: "LiftList",
        component: () =>
          import(/* webpackChunkName: "lifts" */ "@/views/list/lifts/LiftPage"),
        meta: { name: "电梯信息", keepAlive: false }
      },
      {
        path: "faces",
        name: "FaceList",
        component: () =>
          import(/* webpackChunkName: "faces" */ "@/views/list/faces/FacePage"),
        meta: {
          name: "人脸门禁信息",
          keepAlive: false
        }
      },
      {
        path: "controller",
        name: "ControllerList",
        component: () =>
          import(
            /* webpackChunkName: "faces" */ "@/views/list/controller/index"
          ),
        meta: {
          name: "群控器管理",
          keepAlive: false
        }
      },
      {
        path: "elevator",
        name: "elevatorList",
        component: () =>
          import(/* webpackChunkName: "faces" */ "@/views/list/elevator/index"),
        meta: {
          name: "电梯管理",
          keepAlive: false
        }
      },
      {
        path: "elevator_detail",
        name: "elevatorDetail",
        component: () =>
          import(
            /* webpackChunkName: "faces" */ "@/views/list/elevator/detail"
          ),
        meta: {
          name: "电梯管理",
          keepAlive: false
        }
      },
      {
        path: "elevator_edit",
        name: "elevatorEdit",
        component: () =>
          import(/* webpackChunkName: "faces" */ "@/views/list/elevator/edit"),
        meta: {
          name: "电梯管理",
          keepAlive: false
        }
      }
    ]
  }
];
